<template>
  <v-container fluid style="overflow-x:hidden;">
    <section id= "home">
      <div class="home__container" data-aos="fade">
        <div class="title">
          <h2>IOT-클라우드 기반 지능형 양돈사육 솔루션</h2>
        </div>

        <div class="par">
          <p>단편적인 데이터 모니터링을 넘어 검증된 센서를 통해 정확한 모니터링과 농장 환경의 이해 및 개선 방향을 반영한 Algorithm을 구현하여 농장에 맞는 자동화 시스템을 구축해 맞춤형 솔루션을 제공합니다.</p>
        </div>

        <div class="btns">
          <button class='btns_more' onClick="window.open('https://youtube.com/playlist?list=PLoM0FtDfQX0bWR20laaQ4PI99UN3ecLK7')" target="_blank"> See more </button>
        </div>
      </div>

      <div class="container_outer_img">
        <div class="img-inner">
          <img :src="about_main"   class="container_img"/>
        </div>
      </div> 

      <div class="custom-shape-divider-bottom-1686534810">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
    </div>

    </section>
  
    <section id="about">
      <div class="about__container" data-aos="fade-up" data-aos-duration="3000">
        <div class="about__title">
          <h2>ABOUT</h2>
          <div class="about__info">원스프링은 최적의 생육환경을 조성하는 스마트 축산 시스템입니다.</div>
          <div class="about__under"></div>
        </div>


        <div class="about__sub_container">
          <div class="underline"></div>

          <div class="about__content">
            <div class="about__icon">축산 IOT 토탈 솔루션</div>
            <div class="about__icon">악취 모니터링 시스템</div>
            <div class="about__icon">환경측정센서 유통</div>
            <div class="about__icon">팬 컨트롤러 판매</div>
          </div>
        </div>
      </div>
    </section>

    <section id="service">
      <div class="service__container" data-aos="fade-right" data-aos-duration="2000" >
        <div class="service__title">
          <div>SERVICE</div>
        </div>

        <div class="service__category">
          <nav v-bind:class="active" v-on:click.prevent class="navbar">
            <a href="#" class="monitor" v-on:click="makeActive('monitor')">모니터링</a>
            <a href="#" class="ventil" v-on:click="makeActive('ventil')">환기제어</a>
            <a href="#" class="deodorizer" v-on:click="makeActive('deodorizer')">악취제어</a>
            <a href="#" class="etc" v-on:click="makeActive('etc')">FORMULATION</a>
          </nav>

        </div>

        <div class="sevice__project mb-15">
          <div class="service__content" v-if="active == 'monitor'">
            <div class="service_monitor">
              <img :src="Summarymonitor"/>
            </div>

            <div>
              <h1>모니터링 서비스</h1>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                농축산 전분야에 활용될 수 있는 쉽게 설치 운영 가능한 디바이스
              </div>

              <div class="service__detail">
                <v-icon>mdi-chevron-double-right</v-icon>
                내구성 높은 환경센서로 실시간 모니터링
              </div>

              <div class="service__detail">
                <v-icon>mdi-chevron-double-right</v-icon>
                실시간 데이터 기반 컨트롤 및 알람
              </div>
              <div style="text-align:center;">
                <v-btn class="btns_down"
              href="/files/PIGRO Manual.pdf" download>PIGRO 사용설명서 다운로드
                </v-btn>
              </div>
            </div>
            
          </div>

          <div class="service__content" v-if="active == 'ventil'">
            <div class="service_monitor">
              <img :src="AutoControl"/>
            </div>

            <div>
              <h1>환기제어 서비스</h1>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                스마트폰을 통한 편리한 현장제어
              </div>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                  실시간 데이터 기반의 자동 제어
              </div>

              <div class="service__detail">
                <v-icon>mdi-chevron-double-right</v-icon>
                스케줄 제어
              </div>

              <div class="service__detail">
                <v-icon>mdi-chevron-double-right</v-icon>
                빅데이터 분석을 통한 최적 환경 분석
              </div>
            </div>
          </div>

          <div class="service__content" v-if="active == 'deodorizer'">
            <div class="service_monitor">
              <img :src="deodorizer" class="img_shadow"/>
            </div>

            <div class="ml-3">
              <h1>악취제어 서비스</h1>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                실시간 악취농도 측정 및 기록
              </div>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                실시간 현장 상황 파악
              </div>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                사용자가 직접 악취 조건을 설정해 악취 자동 제어 시스템 
              </div>

              <v-btn class="btns_down"
              href="/files/PIGRO Deodorizer Manual.pdf" download>PIGRO 악취제어 설명서 다운로드
              </v-btn>

            </div>
          </div>

          <div class="service__content" v-if="active == 'etc'">
            <div class="service_monitor">
              <img :src="formulation" class="img_shadow"/>
            </div>

            <div class="ml-3">
              <h1>Formulation</h1>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                돼지 개체 데이터 분석
              </div>

              <div class="service__detail">
                <v-icon color=#3a4f3f;>mdi-chevron-double-right</v-icon>
                돼지 개체 데이터, 출하 데이터, 사료/약품 데이터를 통합하여 성장 단계 별 최적화 된 사료 formula 제공
              </div>
            </div>
            </div>
          </div>
        </div>
    </section>


    <section id="product">
      <div class="product__container" data-aos="fade-left" data-aos-duration="2000" >
        <div class="product__title">
          <h2>PRODUCT</h2>
        </div>

      <div >
        <v-carousel hide-delimiters class="carousel"> 
          <template v-for="(item, index) in slides"> 
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index"> 
              <v-row class="flex-nowrap" style="height:100%"> 
                <template v-for="(n,i) in columns"> 
                  <template v-if="(+index + i) < slides.length"> 
                    <div :key="i" class="product__info"> 
                      <a class="display-3" :href="slides[+index + i].site " target='_blank'>
                        <v-img class="product__img" contain :src="slides[+index + i].image "></v-img>
                      </a>
                      <div style="color:#3a4f3f; text-align:center;"> {{ slides[+index + i].title }} </div>
                    </div> 
                  </template> 
                </template> 
              </v-row> 
            </v-carousel-item> 
          </template> 
        </v-carousel>  
      </div>
      <div>
      </div>
      </div>
    </section>

    <!-- <section id="gallery">
      <div class="gallery__container" data-aos="fade-left" data-aos-duration="2000" >
        <div class="product__title">
          <h2>설치사진</h2>
        </div>
      <div>
      </div>
      </div>
    </section> -->

    <section id="contact fade-in">
      <div class="contact">
        <div class="contact__info">
          
          <img :src="logo"   class="onespring_logo"/>
          <div class="onespring_contact">
            <div style="font-size:16px;">농업법인주식회사 원스프링</div>
            <div style="font-size:12px;">사업자 등록번호 : 880-88-00627</div>
            <div style="font-size:12px;">전북 전주시 완산구 안행2길 11, 3층 16호</div>
            <div style="font-size:12px;">홍성 | 충청남도 홍성군 홍성읍 월산1길 38, 서진빌딩 4층 </div>
            <div style="font-size:12px;">광명 | 경기도 광명시 양지로 21, 티타워 1309호 </div>
          </div>

          <div class="onespring_info">
            운영시간
            <div style="font-size:13px;">월요일~금요일 / 주말 및 공휴일 휴무</div>
            <div style="font-size:14px;">AM 8:00 ~ PM 5:00</div>
            <div style="font-size:14px;">문의 041-406-7775</div>
          </div>

          <div class="onespring_info">
            SHOP
            <div>
              <a href="https://smartstore.naver.com/onespring_dol/" target="_blank">
                <img :src="smartstore" class="store_logo" />
              </a>
            </div>
          </div>
        </div>
        <div class="contact__title">Copyrightⓒ <span style='color:#8D7519;'>ONESPRING</span> Co., Ltd.  All Rights Reserved. Ver.2022 </div>
      </div>
    </section>
  </v-container>
</template>

<script>

import AOS from 'aos';
import "aos/dist/aos.css";

export default{
  name: "About",
  data(){
    return{
      about_main: require("@/assets/about/about_main.jpeg"),
      about_main2: require("@/assets/about/about_main2.jpeg"),

      active: 'monitor',

      Summarymonitor: require("@/assets/about/Summary_Monitor.jpg"),
      AutoControl: require("@/assets/about/AutoControl.jpg"),
      deodorizer: require("@/assets/about/deodorizer.jpg"),
      formulation: require("@/assets/about/formulation.jpg"),

      logo : require("@/assets/svg/onespring_logo.svg"),
      smartstore: require("@/assets/about/smartstore.png"),


      slides: [
      {
        title: '센서노드',
        image: require("@/assets/about/product/sensornode.jpeg"),
        site: "https://smartstore.naver.com/onespring_dol/products/8252111509"
      },
      {
        title: '멀티게이트',
        image: require("@/assets/about/product/gateway.jpeg"),
        site: "https://smartstore.naver.com/onespring_dol/products/8252040653"
      },
      {
        title: 'DOL114',
        image: require("@/assets/about/product/dol114.jpeg"),
        site: "https://smartstore.naver.com/onespring_dol/products/8248247739"
      },
      {
        title: 'DOL119',
        image:  require("@/assets/about/product/dol119.png"),
        site: "https://smartstore.naver.com/onespring_dol/products/8248363835"
      },
      {
        title: 'DOL53',
        image: require("@/assets/about/product/dol53.jpeg"),
        site: "https://smartstore.naver.com/onespring_dol/products/8247439329"
      },
      {
        title: 'DOL13',
        image: require("@/assets/about/product/dol13.jpeg"),
        site: "https://smartstore.naver.com/onespring_dol/products/8248222034"
      },
      {
        title: 'DOL58',
        image: require("@/assets/about/product/dol 58.png"),
        site: "https://smartstore.naver.com/onespring_dol/products/8251983173"
      },

    ]

    }
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 5;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 4;
      }

      if (this.$vuetify.breakpoint.md) {
        return 3;
      }

      return 1;
    }
  },

  ready: function () {
    this.startRotation();
  },

  created() {
        AOS.init();
  },

	methods: {
		makeActive(item){
      // console.log(item);
			this.active = item;
		},

    
	}
}
</script>

<style>
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  /* overflow-y:auto; */
  box-sizing: border-box;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

:root {
  --green-color: #3a4f3f;
  --lightgreen-color:#6A806F;
  --brightgreen-color: #BCD5C1;
  --sky-color: #E8F3F5;
  --animation-duration: 300ms;
}

.custom-shape-divider-bottom-1686534810 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1686534810 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 58px;
}

.custom-shape-divider-bottom-1686534810 .shape-fill {
    fill: #FFFFFF;
}

.title h2 {
    font-family: 'GmarketSansMedium';
    letter-spacing: -1px;
    font-size: 28px;
    color: var(--green-color);
    margin-bottom: 20px;
    transform: translateY(100px);
    animation: slideUp .8s ease forwards .5s;
    transform : rotate(0.04deg);
}
h1{
  font-family: 'GmarketSansMedium';
  letter-spacing: -1px;
  font-size: 25px;
  color: var(--green-color);
  margin:15px;
  transform : rotate(0.04deg);
}

.par p{
  font-family: 'GmarketSansMedium';
  font-size: 20px;
  line-height: 30px;
  font-weight:600;
  color: var(--lightgreen-color);
  transform: translateY(300px);
  transform : rotate(0.04deg);
  animation: slideUp .8s ease-in-out forwards .8s;
}
#home {
  display: flex;
  padding:50px;
  height: 80vh;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  animation: expand .8s ease forwards;
  will-change: transform;
  background-color: var(--brightgreen-color);
  /* position: relative; */
  transition: all .8s ease;
}
.home__container {
  width: 50%;
}
.container_outer_img {
  margin: 50px;
  width: 50%;
  overflow: hidden;
}   
    
.container_img {
  width: 100%;
  animation: slideIn 1.5s ease-in-out forwards;
}
.container_img:hover{
  transform: scale(1.4);
  transition: .5s;
}

.par {
  height: auto;
  overflow: hidden;
}

.btns {
  height: 100%;
  position: relative;
  width: 150px;
  overflow: hidden;
}

.btns_more {
  background: transparent;
  border: 2px solid var(--green-color);
  border-radius: 50px;
  padding: 8px 12px;
  color: var(--green-color);
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  margin-top: 5px;
  outline: none;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}
.btns_more:hover {
  background-color: var(--green-color);
  color:white;
  transition: all var(--animation-duration) ease-in-out;
}
.btns_down {
  background: #3a4f3f !important;
  border-radius: 50px;
  padding: 8px 15px;
  background-color: var(--green-color);
  color:white !important;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  outline: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}
.btns_down:hover {
  background-color: var(--lightgreen-color);
  color:white;
  box-shadow: 0px 15px 15px rgba(3, 90, 0, 0.37);
  transition: all var(--animation-duration) ease-in-out;
}

#about{
  font-family: 'GmarketSansMedium';
  margin-top:10px;
  padding:20px;
}
.about__info{
  font-size: 20px;
  font-weight:600;
  color: var(--lightgreen-color);
  transform: translateY(300px);
  animation: slideUp .8s ease-in-out forwards .8s;
  transform : rotate(0.04deg);
}
.about__sub_container{
  position: relative;
}
.about__under{
  width: 580px;
  height: 2px;
  background: var(--green-color);
}
.about__content{
  display:flex;
  justify-content: space-between;
  margin: 80px 0;
  padding:50px;
  width:100%;
  /* overflow-y:hidden; */

}
.underline {
  width: 90%;
  height: 2px;
  background: #3a4f3f;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.underline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation: lineanimation 5s 2s forwards ;
}

.about__icon{
  white-space: normal;
  z-index:1;
  width:200px;
  height:200px;
  line-height:200px;
  border-radius: 50%;
  text-align: center;
  background-color:white;
  border: 2px solid var(--lightgreen-color);
  font-size:20px;
  letter-spacing: -1px;
  animation: lineanimation 5s 2s forwards ;
}
.about__icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation: lineanimation 5s 2s forwards ;
}

.about__icon:hover{
  background-color: #EFF2E1;
  transition:.5s;
}

#service{
  font-family: 'GmarketSansMedium';
  text-align: center;
  transform : rotate(0.04deg);
}
.service__title{
  font-size:35px !important;
  font-weight:bold;
  color:var(--green-color);
  padding:30px;
}
.service__container{
  width: 100%;
  height: 100%;
  padding:10px;
  position: relative;
  z-index: 1;
}
.service__container::after {
  width: 100%;
  height: 100%;
  content: "";
  background: url(../assets/about/blackPig.jpeg);
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.1;
}
.navbar{
  display:inline-block;
  margin:5px auto 20px;
  background-color:var(--brightgreen-color);
  box-shadow:0 1px 1px #ccc;
  border-radius:130px;
}
nav a{
  display:inline-block;
  padding: 18px 30px;
  color: white !important;
  font-weight:bold;
  font-size:16px;
  text-decoration:none !important;
  line-height:1;
  text-transform: uppercase;
  background-color:transparent;

  -webkit-transition:background-color 0.25s;
  -moz-transition:background-color 0.25s;
  transition:background-color 0.25s;
}
nav a:first-child{
  border-radius:130px 0 0 130px;
}
nav a:last-child{
  border-radius:0 130px 130px 0;
}
nav.monitor .monitor,
nav.ventil .ventil,
nav.deodorizer .deodorizer,
nav.etc .etc{
  background-color:var(--lightgreen-color);
}

.sevice__project{
  text-align:left;
  margin: 0 auto;
  margin-top:10px;
  padding: 20px;
  width:100vh;
  height:55vh;
  background-color:#fff;
  border-radius:15px;
}
.service__content{
  height:50vh;
  display:flex;
  align-items: center;
  align-content: center;
  animation : fadein 2s;
}
.service_monitor{
  display:flex;
  align-content: center;
}
.service_monitor > img{
  max-width:55vh;
  height:35vh;
}
.img_shadow{
  box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
              0 8px 16px rgba(0,0,0,0.11);
  border-radius: 10px;
}
.service__detail{
  font-size:17px;
  padding:10px;
  letter-spacing: -1px;
  color:var(--lightgreen-color);
}
.product__container{
  padding:30px;
}
.product__content{
  padding:60px 0 60px 0;
}
.carousel{
  height:400px !important;
}
.product__info{
  padding:50px;
  font-family: 'GmarketSansMedium';
  margin: 0 auto;
}

.product__img{
  width:250px;
  height:250px;
  border-radius: 50%;
  cursor: pointer;
  animation : fadein 2s;
}

.product__img :hover{
  background-color:black;
  opacity:0.2;
  transition: all var(--animation-duration) ease-in-out;
}

.product__info_title{
  width:250px;
  text-align: center;
  font-size:17px;
  color:var(--green-color);
}

.contact{
  font-family: 'GmarketSansMedium';
  padding:20px;
  transform : rotate(0.04deg);
  background-color: #E4E7D4;
  color:var(--green-color);
}
.contact__info{
  display:flex; 
  align-items: center; 
}
.onespring_logo{
  width:80px;
}
.onespring_contact{
  margin-left:10px;
  font-size:14px;
  justify-content: center;
}
.onespring_info{
  margin-left:150px;
}
.store_logo{
  width:30px;
}

.contact__title{
  text-align:center;
  letter-spacing: -1px;
  font-size:11px;
  color:#C6A84D;
}



@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes lineanimation {
  0% {
      left: 0;
  }

  100% {
      left: 100%;
  }
}


@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes expand {
  0% {
    transform: translateX(1400px);
  }
  100% {
    transform: translateX(0px);
  }
}


@media screen and (max-width: 820px){
#home {
display: flex;
flex-direction: column;
padding:50px;
height: 100%;
justify-content: space-around;
align-items: center;
color: #fff;
animation: expand .8s ease forwards;
background-color: var(--brightgreen-color);
/* position: relative; */
transition: all .8s ease;
}

.home__container {
  width: 100%;
  height: 100%;
}

.container_outer_img {
  margin:0;
  width:100%;
}
.container_img{
  margin-top:15px;
}

.about__under{
  width:100%;
}
.underline{
  display: none;
}

.about__content{
align-items: center; 
flex-direction: column;
padding:5px; 
}
.about__icon{
  margin-top:15px;
}
.about__icon::before {
  content: "";
  display: none;
}
nav a:first-child{
  border-radius:130px;
}
nav a{
  border-radius:130px;
}
nav a:last-child{
  border-radius:130px;
}

.sevice__project{
  width:100%;
  height:100%;
}
.service__content{
  flex-direction: column;
  height:100%;
}
.service_monitor > img{
  width:100%;
  height:30%;
}
.product__container, .gallery__container{
  padding:0px;
}
.contact__info{
  text-align:center;
  flex-direction: column;
}
.onespring_contact{
  margin-top:15px;
}
.onespring_info{
  margin-left:0px;
  margin-top:20px;
}

.contact__title{
  margin-top:30px;
  text-align:center;
  font-size:8px;
}

}



</style>